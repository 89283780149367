import { Component, inject, Input, OnInit, OnDestroy } from '@angular/core';
import { PendingChangesService } from '../../../shared/pending-changes.service';
import { BaseComponent } from '../../../shared/base.class';
import { takeUntil } from 'rxjs/operators';
import { AppState, getState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { selectCurrentQuestionPendingChanges } from '../../store/question.state';

@Component({
  template: '',
  standalone: true,
})
export class QuestionEditorBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  protected pendingChangesService = inject(PendingChangesService);
  protected store = inject(Store<AppState>);

  ngOnInit(): void {
    this.handlePendingChanges();
    this.handleSavingPendingChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.pendingChangesService.hasPendingChanges = false;
  }

  handlePendingChanges(): void {
    this.store
      .select(selectCurrentQuestionPendingChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasPendingChanges => {
        this.pendingChangesService.hasPendingChanges = hasPendingChanges;
      });
  }

  handleSavingPendingChanges(): void {
    this.pendingChangesService.confirmedSavedChanges$.pipe(takeUntil(this.destroy$)).subscribe(isConfirmed => {
      if (isConfirmed) {
        const questionId = getState(this.store).question.current.question.id;
        this.pendingChangesService.triggerSave(questionId);
      } else {
        this.pendingChangesService.triggerDiscardChanges();
      }
    });
  }
}
