import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { AppState, getState } from '../../../store/models/app.state';
import { updateControlField } from '../../../store/actions/control.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { BaseComponent } from '../../../shared/base.class';
import { FormModel } from '../../../form/form.model';
import { QuestionModel } from '../../question.model';
import { ControlModel } from '../../control.model';
import { ValidationsService } from '../../../core/helpers/validations.service';

@Component({
  selector: 'phar-question-editor-label',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.scss'],
})
export class LabelEditorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() showQuestionLabelAndInfo = true;
  form: UntypedFormGroup;
  titleMaxLength = 30;
  labelMaxLength = 2000;
  infoMaxLength = 500;
  showQuestionIdEditor = true;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private currentControlValidationService: CurrentControlValidationService,
    private changeDetectorRef: ChangeDetectorRef,
    private validationsService: ValidationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();

    if (!this.disabled) {
      this.updateStateOnValueChange();
      this.setFormValidObservable();
    } else {
      this.form.disable();
    }

    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private buildForm(): void {
    const control = getState(this.store).control.current.control;
    this.showQuestionIdEditor = !control.inGroup;
    this.form = this.fb.group({
      title: [control.title, Validators.required],
      label: [
        control.label,
        [this.validationsService.requiredWithStripHtmlValidator, Validators.maxLength(this.labelMaxLength)],
        [
          /*this.uniqueControlLabel(this.store.select(selectCurrentFormStateForm), control)*/
        ],
      ],
      info: [control.info, Validators.maxLength(this.infoMaxLength)],
    });

    if (!this.showQuestionLabelAndInfo) {
      this.form.get('label').disable({ emitEvent: false });
      this.form.get('info').disable({ emitEvent: false });
    }

    if (!this.showQuestionIdEditor) {
      this.form.get('title').disable({ emitEvent: false });
    }
  }

  private initSubscriptions(): void {
    this.currentControlValidationService.markFormAsTouched$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.markAllAsTouched();
      this.changeDetectorRef.markForCheck();
    });
  }

  private updateStateOnValueChange(): void {
    if (this.showQuestionIdEditor) {
      this.form
        .get('title')
        .valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
        .subscribe(title => {
          this.store.dispatch(updateControlField({ field: 'title', value: title }));
        });
    }
    if (this.showQuestionLabelAndInfo) {
      this.form
        .get('label')
        .valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
        .subscribe(label => {
          this.store.dispatch(updateControlField({ field: 'label', value: label }));
        });

      this.form
        .get('info')
        .valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
        .subscribe(info => {
          this.store.dispatch(updateControlField({ field: 'info', value: info }));
        });
    }
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  uniqueControlLabel(form: Observable<FormModel>, control: ControlModel): AsyncValidatorFn {
    const errorMsg = { notUniqueLabel: true };
    const currentControlId: string = control.controlID;

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return form.pipe(
        map(form => Object.values(form.questionsPopulated)),
        map((questions: QuestionModel[]) => [].concat(...questions.map(question => question.controls))),
        map((controls: ControlModel[]) => {
          if (!controls.length) {
            return null;
          }
          const rawValue = this.getRawValue(control.value).toLowerCase().trim();
          const controlsWithSameLabel = controls.filter(control => {
            return control.label?.toLowerCase().trim() === rawValue && control.controlID !== currentControlId;
          });

          return controlsWithSameLabel.length ? errorMsg : null;
        }),
        take(1),
      );
    };
  }

  getRawValue(value: string): string {
    const tempDiv: HTMLDivElement = document.createElement('div');
    tempDiv.innerHTML = value;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
}
