import {
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep, findIndex as _findIndex, isArray, isEmpty } from 'lodash-es';
import { Action, Store } from '@ngrx/store';

import { FormModel, FormStatusEnum, FormTypeEnum } from '../form.model';
import {
  CurrentFormManage,
  selectCurrentFormState,
  selectCurrentFormStateForm,
  selectCurrentFormStateFormPopulatedQuestions,
} from '../store/form.state';
import {
  dispatchedCreateUpdateForm,
  formRemoveQuestion,
  formUpdateQuestion,
  pendingCreateUpdateForm,
  refreshCurrentForm,
  searchFormById,
  updateForm,
  updateFormField,
} from '../store/form.actions';
import { AppState, getState } from '../../store/models/app.state';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { searchDatasetById } from '../../dataset/store/dataset.actions';
import { toggleSidebarSection } from '../../animations';
import { FormButtonsTypesEnum } from './form-buttons-types.enum';
import { UtilsService } from '../../core/utils.service';
import { ElementsEnum, FormElementsEnum } from '../form-elements.enum';
import { PendingChangesControl } from '../../shared/guards/pending-changes.guard';
import { ControlModel } from '../../question/control.model';
import {
  autocreateQuestionSuccess,
  deleteMultipleQuestions,
  resetCurrentQuestion,
  showSaveControlBeforeSavingQuestionDialog,
  updateQuestion,
} from '../../question/store/question.actions';
import { resetCurrentControl } from '../../store/actions/control.actions';
import { QuestionModel } from '../../question/question.model';
import { Actions, ofType } from '@ngrx/effects';
import { PendingChangesService } from '../../shared/pending-changes.service';
import { QuestionService } from '../../question/question.service';
import { CurrentControlValidationService } from '../../shared/services/current-control-validation.service';
import { DataFieldBindingService } from '../../question/editors/bind-data-field-on-label-change/data-field-binding.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { selectQuestionFeatureCurrentQuestion } from '../../question/store/question.state';
import { MobilePreviewFormQuestionsDialogComponent } from '../mobile-preview-form-questions-dialog/mobile-preview-form-questions.component';
import { BaseComponent } from '../../shared/base.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { RuleTypeEnum } from '../../rule/rule-type.enum';
import { RuleActionEnum } from '../../rule/rule-action.enum';
import { toSignal } from '@angular/core/rxjs-interop';

enum WidgetEditorType {
  Pages,
  Properties,
  Rules,
}

@Component({
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss'],
  animations: [toggleSidebarSection],
})
export class FormContentComponent extends BaseComponent implements OnInit, OnDestroy, PendingChangesControl {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('formContent', { static: true }) formContent: ElementRef;
  @ViewChild('formContentBody') formContentBody: ElementRef;

  formState$: Observable<CurrentFormManage>;
  currentForm$: Observable<FormModel>;
  currentForm: Signal<FormModel>;
  builderMode: Signal<boolean>;
  allowSelectEditMode: Signal<boolean>;
  editMode = null;
  FormElementsEnum = FormElementsEnum;
  hasPendingChanges$: Observable<boolean>;

  elementsIsOpen = 'open';
  widgetsIsOpen = 'open';
  questionsIsOpen = 'open';

  questionsSelectedIndex = 0;

  pageEvent: PageEvent = {
    pageSize: 1,
    pageIndex: 0,
    length: 1,
  };
  pageSizeOptions = [1];
  pageIndexCandidate = null;

  hideLeftAside = false;
  isRightBarFullWidth = false;
  isContentFullWidth = false;

  hideRightAside = false;

  selectedControl$: Observable<ControlModel | null>;
  selectedControlRulesLength$: Observable<number>;
  selectedQuestion$: Observable<QuestionModel | null>;
  selectedQuestionSnapshot$: Observable<QuestionModel | null>;
  widgetEditorActive = WidgetEditorType.Properties;
  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };
  readonly disabledRulesFormElements = [
    FormElementsEnum.QuestionGroup,
    FormElementsEnum.TextBlock,
    FormElementsEnum.TextInput,
    FormElementsEnum.NumericRatingScale,
    FormElementsEnum.VisualAnalogScale,
    FormElementsEnum.TimePicker,
    FormElementsEnum.DatePicker,
    FormElementsEnum.Header,
    FormElementsEnum.Footer,
    FormElementsEnum.Image,
    FormElementsEnum.SingleSelect,
    FormElementsEnum.Number,
  ];
  readonly buttonTypes = FormButtonsTypesEnum;
  readonly formTypes = FormTypeEnum;
  readonly WidgetEditorType = WidgetEditorType;
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;
  private readonly injector = inject(Injector);

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private confirmDialog: PharConfirmDialogService,
    public dialog: MatDialog,
    public pendingChangesService: PendingChangesService,
    private questionService: QuestionService,
    private utilsService: UtilsService,
    private currentControlValidationService: CurrentControlValidationService,
    private dataFieldBindingService: DataFieldBindingService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formState$ = this.store.select(selectCurrentFormState);
    this.currentForm$ = this.store.select(selectCurrentFormStateForm);
    this.currentForm = toSignal(this.currentForm$, { injector: this.injector });
    this.builderMode = computed(() => {
      const form = this.currentForm();
      return !form.isLocked && !form.isArchived;
    });
    this.allowSelectEditMode = computed(() => {
      const form = this.currentForm();
      return (!form.isLocked || form.formStatus === FormStatusEnum.Released) && !form.isArchived;
    });
    this.hasPendingChanges$ = this.pendingChangesService.hasPendingChanges$.pipe(
      filter(() => !this.questionService.isQuestionAutoCreateMode()),
    );
    this.selectedControl$ = this.store
      .select(state => state.control.current.control)
      .pipe(map(control => (control.controlID ? control : null)));
    this.selectedControlRulesLength$ = this.selectedControl$.pipe(
      map((control: ControlModel | null) => {
        let rulesLength = 0;

        if (!control || isEmpty(control.dependencies)) {
          return rulesLength;
        }

        Object.keys(control.dependencies).forEach((ruleType: RuleTypeEnum) => {
          if (isArray(control.dependencies[ruleType])) {
            rulesLength += control.dependencies[ruleType].length;
            return;
          }

          Object.keys(control.dependencies[ruleType]).forEach((ruleAction: RuleActionEnum) => {
            if (isArray(control.dependencies[ruleType][ruleAction].data)) {
              rulesLength += control.dependencies[ruleType][ruleAction].data.length;
            }
          });
        });

        return rulesLength;
      }),
    );

    this.selectedQuestionSnapshot$ = this.store.select(selectQuestionFeatureCurrentQuestion).pipe(
      map(state => state.questionSnapshot),
      distinctUntilChanged(),
    );

    let datasetLoaded = false;
    this.currentForm$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(form => {
      if (this.pageIndexCandidate && this.currentForm().body.pages[this.pageIndexCandidate]) {
        this.navigateToCertainPage(this.pageIndexCandidate);
      }
      if (form.datasetId && !datasetLoaded) {
        datasetLoaded = true;
        this.store.dispatch(searchDatasetById({ id: form.datasetId }));
      }
    });

    this.handleSelectedTab();
    this.handleSaveFormOnAutocreateQuestion();
    this.exitFullscreenContentOnSelectControl();
    this.store.dispatch(resetCurrentControl());
    this.store.dispatch(resetCurrentQuestion());

    this.actions$
      .pipe(
        ofType<ReturnType<typeof showSaveControlBeforeSavingQuestionDialog>>(showSaveControlBeforeSavingQuestionDialog),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.displaySaveControlFirstModal();
      });
    this.checkQueryParams();

    this.pendingChangesService.discardChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.discardChanges();
    });
  }

  ngOnDestroy(): void {
    if (getState(this.store).form.current.pendingChanges) {
      this.store.dispatch(refreshCurrentForm());
    }
    super.ngOnDestroy();
  }

  saveAndCheckPendingChanges(): Observable<boolean> {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return of(true);
    }

    this.pendingChangesService.saveChanges();

    return this.pendingChangesService.hasPendingChanges$.pipe(filter(hasChanges => !hasChanges));
  }

  saveForm(): void {
    this.utilsService.dispatchActions(this.store, [
      pendingCreateUpdateForm({ pending: true }),
      dispatchedCreateUpdateForm({ dispatched: true }),
      updateForm({ form: this.currentForm() }),
    ]);

    this.formState$
      .pipe(
        filter(({ dispatched, success }) => dispatched && success),
        take(1),
      )
      .subscribe(() => {
        this.utilsService.dispatchActions(this.store, [
          pendingCreateUpdateForm({ pending: false }),
          dispatchedCreateUpdateForm({ dispatched: false }),
        ]);
      });
  }

  editHeaderFooter(type: FormElementsEnum.Header | FormElementsEnum.Footer): void {
    if (this.editMode === type) {
      return;
    }
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.resetCurrentControlAndQuestion();
    this.updateEditMode(type);
  }

  removeHeaderFooter(type: FormElementsEnum.Header | FormElementsEnum.Footer): void {
    this.confirmDialog
      .openConfirmDialog(`Do you want to remove ${type.toLowerCase()}?`)
      .pipe(
        take(1),
        filter(isConfirmed => isConfirmed),
        withLatestFrom(this.store.select(selectCurrentFormStateForm)),
      )
      .subscribe({
        next: ([, form]) => {
          const formSettings = cloneDeep(form.settings);
          const formBody = cloneDeep(form.body);

          if (type === FormElementsEnum.Header) {
            formSettings.showHeader = false;
            formBody.header = { text: '', isSiteLevel: false };
          }
          if (type === FormElementsEnum.Footer) {
            formSettings.showFooter = false;
            formBody.footer = { text: '', isSiteLevel: false };
          }

          this.store.dispatch(
            updateForm({
              form: {
                ...form,
                settings: formSettings,
                body: formBody,
              },
            }),
          );
          this.updateEditMode();
        },
      });
  }

  addPage(beforeCurrent = false): void {
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }
    this.resetRightPanel();
    const { pageIndex } = this.pageEvent;

    const newPageIndex = beforeCurrent ? pageIndex : pageIndex + 1;
    const newPage = this.createEmptyPage(newPageIndex);

    const updatedPages = [
      ...this.currentForm().body.pages.slice(0, newPageIndex),
      newPage,
      ...this.currentForm().body.pages.slice(newPageIndex),
    ].map((page, index) => ({
      ...page,
      title: `Page ${index + 1}`,
    }));

    const updatedQuestions = this.getQuestionsWithReindexControls(updatedPages, newPageIndex + 1);
    //
    this.store.dispatch(
      updateFormField({
        field: 'body',
        value: { ...this.currentForm().body, pages: updatedPages },
      }),
    );
    this.resetCurrentControlAndQuestion();

    this.questionService
      .updateMultiple(updatedQuestions)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.saveForm();
          this.pageEvent.pageIndex = newPageIndex;
        },
      });
  }

  removePage(): void {
    const childQuestions = this.currentForm().body.pages[this.pageEvent.pageIndex].questions;
    const confirmMessage = childQuestions.length
      ? 'This page contains questions and/or other components. Do you want to remove the page and the components'
      : 'Do you want to remove this page?';

    this.confirmDialog
      .openConfirmDialog(confirmMessage)
      .pipe(
        take(1),
        filter(isConfirmed => isConfirmed),
        withLatestFrom(this.store.select(selectCurrentFormStateFormPopulatedQuestions)),
        switchMap(([, questionsPopulated]) => {
          const pageControls: ControlModel[] = [].concat(...childQuestions.map(q => questionsPopulated[q.id].controls));
          // first we need to clear the questions on that page one by one and then remove the entire page
          childQuestions.forEach((q: QuestionModel) => {
            this.dispatchFormRemoveQuestion(q, this.pageEvent.pageIndex, false);
          });
          this.currentForm().body.pages = [...this.getPageTitlesAfterPageDelete(this.pageEvent.pageIndex)];
          this.currentForm().body.pages.splice(this.pageEvent.pageIndex, 1);

          const updatedQuestions = this.getQuestionsWithReindexControls(
            this.currentForm().body.pages,
            this.pageEvent.pageIndex,
          );

          this.updateFormBody();
          this.resetCurrentControlAndQuestion();
          this.paginator.previousPage();
          this.dataFieldBindingService.removeControlsDataFields(pageControls);
          this.dataFieldBindingService.saveDataFields();
          // manually call delete questions to wipe it from the DB
          this.store.dispatch(deleteMultipleQuestions({ ids: childQuestions.map(q => q.id) }));
          return this.questionService.updateMultiple(updatedQuestions);
        }),
      )
      .subscribe(() => {
        this.saveForm();
      });
  }

  updatePage(event): void {
    const index = _findIndex(this.currentForm().body.pages, page => page.pageID === event.data.pageID);
    this.currentForm().body.pages[index] = event.data;
    if (event.type === 'removeElement' && event.element.controlType !== ElementsEnum.Textblock) {
      this.removeQuestionControlsDataFields(event.element.id);
      this.dispatchFormRemoveQuestion(event.element, index);
      // remove question from the DB
      this.store.dispatch(deleteMultipleQuestions({ ids: [event.element.id] }));
      this.resetRightPanel();
    }
    this.updateFormBody(event.type === 'dropElement', event.type === 'removeElement');
  }

  getQuestionsWithReindexControls(pages: any[], startPageIndex: number): QuestionModel[] {
    const currenFormQuestionsPopulated = cloneDeep(this.currentForm().questionsPopulated);
    const pageIndexes = pages.map((_, index) => index).slice(startPageIndex);

    return pageIndexes.reduce((questions: QuestionModel[], pageIndex: number) => {
      const pageQuestionIds: number[] = pages[pageIndex].questions.map(({ id }) => id);
      const pageQuestions: QuestionModel[] = pageQuestionIds.map(questionId => {
        return currenFormQuestionsPopulated[questionId];
      });

      const updatedPageQuestions: QuestionModel[] = pageQuestions.map(question => {
        const controls: ControlModel[] = question.controls;
        const updatedControls: ControlModel[] = controls.map(control => {
          return { ...control, pageIndex } as ControlModel;
        });

        return { ...question, pageIndex, controls: updatedControls };
      });

      return [...questions, ...updatedPageQuestions];
    }, []);
  }

  dispatchFormRemoveQuestion(question, pageIndex, save = true): void {
    let dispatch = true;
    const pages = this.currentForm().body.pages;
    for (let i = 0; i < pages.length; i++) {
      if (i !== pageIndex) {
        const index = pages[i].questions.findIndex(d => d.id === question.id);
        if (index > -1) {
          dispatch = false;
          break;
        }
      }
    }
    if (dispatch) {
      this.store.dispatch(formRemoveQuestion({ questionId: question.id }));
      this.store.dispatch(resetCurrentQuestion());
      this.store.dispatch(resetCurrentControl());
      if (save) {
        this.saveAndCheckPendingChanges();
      }
    }
  }

  removeQuestionControlsDataFields(questionId: string): void {
    const question: QuestionModel = getState(this.store).form.current.form.questionsPopulated[questionId];
    if (!question) {
      return;
    }

    question.controls.forEach(control => {
      this.dataFieldBindingService.removeControlDataFields(control);
    });
    this.dataFieldBindingService.saveDataFields();
  }

  afterDeleteQuestion(id): void {
    this.store.dispatch(formRemoveQuestion({ questionId: id }));
  }

  afterUpdateQuestion(question): void {
    this.store.dispatch(formUpdateQuestion({ question }));
  }

  checkPendingChangesOnClick() {
    setTimeout(() => {
      if (this.pendingChangesService.hasPendingChanges) {
        this.pendingChangesService.openPendingChangesSaveDialog();
      }
    }, 150);
  }

  discardChanges() {
    this.store.dispatch(resetCurrentControl());
    this.store.dispatch(resetCurrentQuestion());
    this.store.dispatch(refreshCurrentForm());
    this.widgetEditorActive = WidgetEditorType.Properties;
    this.updateEditMode();
    this.store.dispatch(searchFormById({ id: this.currentForm().id }));
  }

  saveCurrentQuestionPendingChanges() {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    const currentQuestion = getState(this.store).question.current.question;
    this.store.dispatch(updateQuestion({ question: currentQuestion }));
    this.dataFieldBindingService.saveDataFields();
  }

  setActiveTabIndex(widgetEditorType: WidgetEditorType) {
    // Prevent opening rules if we have invalid control inputs.
    if (widgetEditorType === WidgetEditorType.Rules && !this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    if (this.questionService.isQuestionAutoCreateMode()) {
      return;
    }

    this.widgetEditorActive = widgetEditorType;
    if (widgetEditorType === WidgetEditorType.Pages) {
      this.store.dispatch(resetCurrentControl());
      this.store.dispatch(resetCurrentQuestion());
    }
  }

  toggleContentFullWidth(): void {
    this.isContentFullWidth = !this.isContentFullWidth;
  }

  toggleRightBarFullWidth(): void {
    this.isRightBarFullWidth = !this.isRightBarFullWidth;
    window.dispatchEvent(new Event('resize'));
  }

  toggleWidgetsPanel(): void {
    this.widgetsIsOpen = this.widgetsIsOpen === 'open' ? 'close' : 'open';
  }

  toggleQuestionsPanel(): void {
    this.questionsIsOpen = this.questionsIsOpen === 'open' ? 'close' : 'open';
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.currentForm().body.pages[this.pageEvent.pageIndex].buttons,
      event.previousIndex,
      event.currentIndex,
    );
    this.updateFormBody();
  }

  updateFormBody(updatePageIndexes = false, shouldUpdateForm = false): void {
    const value = {
      ...this.currentForm().body,
      pages: this.currentForm().body.pages,
    };
    if (updatePageIndexes) {
      value.pages = value.pages.map(page => {
        return {
          ...page,
          questions: page.questions.map((question, index) => {
            return { ...question, order: index };
          }),
        };
      });
    }

    this.store.dispatch(
      updateFormField({
        field: 'body',
        value,
      }),
    );
    if (shouldUpdateForm) {
      this.store.dispatch(updateForm({ form: getState(this.store).form.current.form }));
    }
  }

  updateEditMode(editMode?: FormElementsEnum) {
    const propertiesEditorModes = [FormElementsEnum.QuestionGroup, FormElementsEnum.Header, FormElementsEnum.Footer];

    this.editMode = editMode;

    if (propertiesEditorModes.includes(this.editMode)) {
      this.widgetEditorActive = WidgetEditorType.Properties;
    }
  }

  onPageChange(event: PageEvent): void {
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      this.paginator.pageIndex = this.pageEvent.pageIndex;
      return;
    }
    this.pageEvent = event;
    this.resetCurrentControlAndQuestion();
    this.resetRightPanel();
    this.scrollToTop();
  }

  resetCurrentControlAndQuestion(): void {
    const actions: Action[] = [resetCurrentControl(), resetCurrentQuestion()];

    this.utilsService.dispatchActions(this.store, actions);
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  mobileFormPreview(form: FormModel): void {
    this.dialog.open(MobilePreviewFormQuestionsDialogComponent, {
      data: {
        form,
      },
    });
  }

  scrollToTop(): void {
    const formContentNative = this.formContentBody.nativeElement;

    if (formContentNative.clientHeight < formContentNative.scrollHeight) {
      formContentNative.scrollTop = 0;
    }
  }

  handleRequestPageChange(pageIndex: number): void {
    if (pageIndex !== this.pageEvent.pageIndex) {
      this.pageEvent.pageIndex = pageIndex;
    }
  }

  handleRequestEditQuestion(question: QuestionModel): void {
    if (!question?.controls.length) {
      return;
    }

    this.utilsService.waitForElementToExist(`#ctrl-${question.controls[0].controlID}`, 9999).then((el: HTMLElement) => {
      el.click();
    });
  }

  handleRequestRightPanel(): void {
    this.resetRightPanel();
  }

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.formContent.nativeElement).getPropertyValue('--rightBarWidth'), 10);
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.formContent.nativeElement.style.setProperty('--rightBarWidth', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  private exitFullscreenContentOnSelectControl(): void {
    this.selectedControl$
      .pipe(
        map(control => control?.controlID),
        distinctUntilChanged(),
        filter(controlId => !!controlId),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.isContentFullWidth = false;
      });
  }

  private handleSaveFormOnAutocreateQuestion() {
    this.actions$
      .pipe(ofType<ReturnType<typeof autocreateQuestionSuccess>>(autocreateQuestionSuccess), takeUntil(this.destroy$))
      .subscribe(() => {
        this.saveForm();
      });
  }

  private handleSelectedTab() {
    this.selectedControl$
      .pipe(
        map(control => control?.controlID),
        distinctUntilChanged(),
        filter(controlId => !!controlId),
        filter(() => this.widgetEditorActive !== WidgetEditorType.Properties),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.widgetEditorActive = WidgetEditorType.Properties;
      });
  }

  private getPageTitlesAfterPageDelete(deletedIndex: number): any[] {
    return this.currentForm().body.pages.map((page, index) => {
      if (index > deletedIndex) {
        let pageNumber = page.title.split(' ')[1];
        pageNumber = pageNumber - 1;
        const title = 'Page ' + pageNumber;
        return { ...page, title };
      }
      return page;
    });
  }

  private displaySaveControlFirstModal() {
    this.confirmDialog
      .openConfirmDialog('Please save control before saving question?', '', 'Close', '')
      .pipe(take(1))
      .subscribe();
  }

  private createEmptyPage(newPageIndex: number) {
    return {
      title: `Page ${newPageIndex}`,
      pageID: `P-${new Date().getTime()}`,
      page_uuid: this.utilsService.generateUUID(),
      questions: [],
      buttons: [],
    };
  }

  private resetRightPanel() {
    this.widgetEditorActive = WidgetEditorType.Properties;
    this.updateEditMode(null);
  }

  private checkQueryParams(): void {
    if (this.route.snapshot.queryParams['page']) {
      const page = Number(this.route.snapshot.queryParams['page']);
      this.clearQueryParams();
      if (isNaN(page) || page < 2) {
        return;
      }
      if (!this.currentForm().id) {
        this.pageIndexCandidate = page - 1;
        return;
      }
      if (!this.currentForm().body.pages[page - 1]) {
        return;
      }
      this.navigateToCertainPage(page - 1, false);
    }
  }

  private navigateToCertainPage(page: number, clearParams = true): void {
    this.pageEvent.pageIndex = page;
    //clear the pageIndexCandidate and the query params
    this.pageIndexCandidate = null;
    if (!clearParams) {
      return;
    }
    this.clearQueryParams();
  }

  private clearQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  protected readonly FormStatusEnum = FormStatusEnum;
}
