<input #fileInput class="d-none" type="file" (change)="fileChange($event)" />

<phar-editor-template [controlType]="FormElementsEnum.Image">
  <phar-question-editor-label general [disabled]="disabled" />
  <div response>
    <button mat-stroked-button [disabled]="disabled" (click)="fileInput.click()">
      <mat-icon svgIcon="plus" />
      Upload image
    </button>
  </div>
</phar-editor-template>
