import { Component, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../store/models/app.state';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

// Based on CheckboxEditorComponent

@Component({
  selector: 'phar-question-editor-multi-select-quantity',
  templateUrl: './multi-select-quantity-editor.component.html',
  styleUrls: ['./multi-select-quantity-editor.component.scss'],
})
export class MultiSelectQuantityEditorComponent extends QuestionEditorBaseComponent implements OnDestroy {
  @Input() parentDataField = '';

  constructor(
    protected store: Store<AppState>,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
