import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { AppState, getState } from '../../store/models/app.state';
import { selectCurrentQuestion } from '../store/question.state';
import { globalLoading, setDragged } from '../../store/actions/ui.actions';
import { formUpdateQuestion, updateForm } from '../../form/store/form.actions';
import {
  populateCurrentQuestion,
  showSaveControlBeforeSavingQuestionDialog,
  updateQuestion,
  updateQuestionField,
} from '../store/question.actions';
import { QuestionModel } from '../question.model';
import { ControlModel } from '../control.model';
import { FormElementsEnum } from '../../form/form-elements.enum';
import { PharDragService } from '../../shared/drag.service';
import { PendingChangesService } from '../../shared/pending-changes.service';
import { DataFieldBindingService } from '../editors/bind-data-field-on-label-change/data-field-binding.service';
import { UtilsService } from '../../core/utils.service';
import { QuestionService } from '../question.service';
import { CurrentControlValidationService } from '../../shared/services/current-control-validation.service';
import { cloneDeep, isEqual } from 'lodash-es';
import { DuplicateDialogComponent } from '../duplicate-dialog/duplicate-dialog.component';
import { FormModel, FormTypeEnum } from '../../form/form.model';
import { IDuplicateDialogData } from '../duplicate-dialog/duplicate-dialog-data.interface';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { EntityCommentState } from '../../shared/entity-comments/entity-comment.state.enum';
import { selectCurrentFormStateForm } from '../../form/store/form.state';

@Component({
  selector: 'phar-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss'],
})
export class QuestionViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() question: QuestionModel;
  @Input() builderMode = true;
  @Input() allowSelectEditMode = true;
  @Input() isCommentsVisible = true;
  @Input() isVerticalLayout = false;
  @Input() isDraggable = false;
  @Input() hasChanges = false;
  @Output() public afterUpdate = new EventEmitter<any>();
  @Output() public afterDelete = new EventEmitter<any>();
  @Output() public selectEditMode = new EventEmitter<any>();
  @Output() public afterDragStart = new EventEmitter<any>();
  @Output() public afterDragEnd = new EventEmitter<any>();
  @Output() public requestPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public requestEditQuestion: EventEmitter<QuestionModel> = new EventEmitter<QuestionModel>();
  @Output() public requestResetRightPanel: EventEmitter<any> = new EventEmitter<any>();

  canSaveQuestion$: Observable<boolean>;
  controls = [];
  elementDragged: Observable<boolean>;
  elementResized: Observable<boolean>;
  elementDraggedHelper$: Observable<any>;
  FormElementsEnum = FormElementsEnum;
  isControlHovered$: Observable<boolean>;
  isFormQuestion = false;
  isQuestionActive$: Observable<boolean>;

  private readonly destroy$ = new Subject<null>();
  private _isControlHovered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private currentControlValidationService: CurrentControlValidationService,
    public dialog: MatDialog,
    private dragService: PharDragService,
    private pendingChangesService: PendingChangesService,
    private store: Store<AppState>,
    private dataFieldBindingService: DataFieldBindingService,
    private utilsService: UtilsService,
    private questionService: QuestionService,
    private confirmDialog: PharConfirmDialogService,
  ) {}

  @HostListener('mouseenter')
  mousEenter() {
    this._isControlHovered$.next(true);
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this._isControlHovered$.next(false);
  }

  ngOnInit(): void {
    this.elementDragged = this.store.select(state => state.ui.dragged);
    this.elementResized = this.store.select(state => state.ui.resized);
    this.controls = this.question.controls;
    this.canSaveQuestion$ = this.store.select(selectCurrentQuestion).pipe(map(question => !!question.id));
    this.elementDraggedHelper$ = this.store.select(state => state.ui.draggedHelper);
    this.isQuestionActive$ = this.store
      .select(selectCurrentQuestion)
      .pipe(map(question => question.id === this.question.id));

    if (this.question.formId) {
      this.isFormQuestion = true;
    }
    this.isControlHovered$ = this._isControlHovered$.asObservable();

    this.pendingChangesService.triggerSave$
      .pipe(
        filter(triggerId => triggerId === this.question.id),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.saveQuestion(new Event('fakeEvent'), this.question);
        },
      });
  }

  ngOnChanges(): void {
    this.controls = this.question.controls;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  editQuestion(event, question): void {
    const currentQuestionClicked = this.isCurrentQuestionClicked(question);
    if (!this.allowSelectEditMode) {
      return;
    }
    if (this.question.isGroup) {
      if (this.pendingChangesService.hasPendingChanges && !currentQuestionClicked) {
        this.pendingChangesService.openPendingChangesSaveDialog();
        return;
      }
      // if you click somewhere else in the question container, edit should be emitted to be able to see edit question component
      this.setSelectEditMode({
        control: null,
        type: FormElementsEnum.QuestionGroup,
        parentDataField: '',
      });
      this.store.dispatch(populateCurrentQuestion({ question }));
      return;
    } else {
      // reset question editor if its already active
      this.setSelectEditMode(null);
    }

    if (this.hasChanges && currentQuestionClicked) {
      // Left in case the editor is reverted
    } else if (this.pendingChangesService.hasPendingChanges && !currentQuestionClicked) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    } else {
      // Normal from
      event.stopImmediatePropagation();
      this.store.dispatch(populateCurrentQuestion({ question: this.question }));
      // Left in case the editor is reverted
    }
  }

  saveQuestion(event, question) {
    if (this.questionService.shouldSaveControlBeforeSaveQuestion()) {
      this.store.dispatch(showSaveControlBeforeSavingQuestionDialog());
      return;
    }

    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    this.checkShouldPropagate(this.hasChanges, event);
    const currentQuestionClicked = this.isCurrentQuestionClicked(question);
    if (this.hasChanges && currentQuestionClicked) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(updateQuestion({ question }));
      this.dataFieldBindingService.saveDataFields();
    } else if (this.hasChanges && !currentQuestionClicked) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    } else {
      // Normal from
      event.preventDefault();
      event.stopImmediatePropagation();
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(updateQuestion({ question }));
      this.dataFieldBindingService.saveDataFields();
    }
  }

  moveQuestionToPage() {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    if (this.hasChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.dialog
      .open(DuplicateDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          currentPageIndex: this.question.pageIndex,
          mode: 'move',
        } as IDuplicateDialogData,
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        withLatestFrom(this.store.select(selectCurrentFormStateForm)),
        switchMap(([result, form]) => {
          const countOfPageQuestions = form.body.pages[result.pageIndex]?.questions?.length;

          if (form.type === FormTypeEnum.DataCapture && countOfPageQuestions) {
            return this.confirmDialog
              .openConfirmDialog(
                'Would you like to continue anyway or discard and add this question to a new page?',
                'Government regulations require that ePROs only have one question per page.',
                'Continue',
                'Discard',
                false,
                350,
              )
              .pipe(
                filter(isConfirmed => !!isConfirmed),
                map(() => result),
              );
          }

          return of(result);
        }),
        take(1),
      )
      .subscribe({
        next: (result: { pageIndex: number }) => {
          //update question;
          const question = { ...cloneDeep(this.question), pageIndex: result.pageIndex };
          const currentPageIndex = this.question.pageIndex;
          const form: FormModel = cloneDeep(getState(this.store).form.current.form);
          const pages = form.body.pages;
          const targetPage = pages[result.pageIndex];
          question.controls.map(control => {
            control.pageIndex = result.pageIndex;
            control.page_uuid = targetPage.page_uuid;
          });
          //update question end

          //update form;
          const itemOnACurrentPage = pages[currentPageIndex].questions.find(
            (q: { id: number }) => q.id === this.question.id,
          );
          //remove from current page
          pages[currentPageIndex].questions = pages[currentPageIndex].questions.filter(
            (q: { id: number }) => q.id !== this.question.id,
          );
          //add to the new one
          targetPage.questions.push(itemOnACurrentPage);
          //update question and the whole form
          this.utilsService.dispatchActions(this.store, [
            updateQuestion({ question }),
            updateForm({
              form: {
                ...form,
                body: {
                  ...form.body,
                  pages: pages.map(page => ({
                    ...page,
                    //fixing the order
                    questions: page.questions.map((question, index) => ({
                      ...question,
                      order: index,
                    })),
                  })),
                },
              },
            }),
          ]);
          this.requestResetRightPanel.emit();
        },
      });
  }

  duplicateQuestion(): void {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    if (this.hasChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    const form: FormModel = cloneDeep(getState(this.store).form.current.form);

    this.dialog
      .open(DuplicateDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          currentPageIndex: this.question.pageIndex,
          mode: 'duplicate',
        } as IDuplicateDialogData,
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        switchMap(result => {
          const countOfPageQuestions = form.body.pages[result.pageIndex]?.questions?.length;

          if (form.type === FormTypeEnum.DataCapture && countOfPageQuestions) {
            return this.confirmDialog
              .openConfirmDialog(
                'Would you like to continue anyway or discard and add this question to a new page?',
                'Government regulations require that ePROs only have one question per page.',
                'Continue',
                'Discard',
                false,
                350,
              )
              .pipe(
                filter(isConfirmed => !!isConfirmed),
                map(() => result),
              );
          }

          return of(result);
        }),
      )
      .subscribe({
        next: (result: { pageIndex: number }) => {
          let usedData = {
            labels: [],
            titles: [],
          };
          // prepare control and question
          const controlsWithUpdatedDataFields = this.question.controls
            .filter(c => c.controlType !== 'dropzone')
            .map((baseControl: ControlModel) => {
              const { title, label } = this.utilsService.generateNewControlTitleAndLabel(
                baseControl,
                this.store,
                {
                  title: 250,
                  label: 2000,
                },
                usedData,
              );
              usedData = {
                labels: [...usedData.labels, label, baseControl.label],
                titles: [...usedData.titles, title, baseControl.title],
              };
              const control: ControlModel = {
                ...baseControl,
                title,
                label,
                bindDataField: '',
                controlID: 'C-' + this.utilsService.generateUniqueNumber(),
                question_uuid: this.utilsService.generateUUID(),
                commentsState: EntityCommentState.NoComments,
                pageIndex: result.pageIndex,
                page_uuid: getState(this.store).form.current.form.body.pages[result.pageIndex].page_uuid,
              };
              const controlWithBoundDataField = this.dataFieldBindingService.bindDataFieldToTheControl(control, false);

              return controlWithBoundDataField ? controlWithBoundDataField : control;
            });

          const newQuestion: QuestionModel = {
            ...this.question,
            title: this.generateQuestionTitle(this.question),
            createdAt: null,
            updatedAt: null,
            controls: controlsWithUpdatedDataFields,
            pageIndex: result.pageIndex,
          };
          // end prepare control and question

          this.questionService
            .create(newQuestion)
            .pipe(take(1))
            .subscribe({
              next: (duplicateQuestion: QuestionModel) => {
                const form: FormModel = cloneDeep(getState(this.store).form.current.form);

                const questions = [...form.questions, duplicateQuestion.id];
                const questionsPopulated = {
                  ...form.questionsPopulated,
                  [duplicateQuestion.id]: duplicateQuestion,
                };
                const pages = form.body.pages.map((page, index: number) => {
                  if (index !== result.pageIndex) {
                    // we are targeting only selected page so return
                    return page;
                  }

                  const questionID = 'Q-' + new Date().getTime();

                  //if the new question needs to be added to another page
                  if (this.question.pageIndex !== result.pageIndex) {
                    return {
                      ...page,
                      questions: [...page.questions, { id: duplicateQuestion.id, questionID }],
                    };
                  }

                  //adding duplicated question below original question in the same page
                  if (page.questions.some(question => question.id === this.question.id)) {
                    const updatedQuestions = page.questions.map(question => {
                      const id = duplicateQuestion.id;
                      return question.id === this.question.id ? [question, { id, questionID }] : [question];
                    });

                    return {
                      ...page,
                      questions: [].concat(...updatedQuestions),
                    };
                  } else {
                    return page;
                  }
                });

                /*const newPage = {
                pageID: 'P-' + new Date().getTime(),
                page_uuid: this.utilsService.generateUUID(),
                questions: [ {
                  id: duplicateQuestion.id,
                  questionID: `Q-${new Date().getTime()}`
                } ],
                buttons: []
              };

              const pageIndexWithOriginalQuestion = form.body.pages.findIndex((page) => {
                return page.questions.some((question) => question.id === this.question.id);
              });
              const nextPageIndex = pageIndexWithOriginalQuestion + 1;

              const pages = [
                ...form.body.pages.slice(0, nextPageIndex),
                newPage,
                ...form.body.pages.slice(nextPageIndex),
              ].map((page, index) => ({
                ...page,
                title: `Page ${index + 1}`
              }));*/

                // update the form
                this.store.dispatch(
                  updateForm({
                    form: {
                      ...form,
                      questions,
                      questionsPopulated,
                      body: {
                        ...form.body,
                        pages: pages.map(page => ({
                          ...page,
                          questions: page.questions.map((question, index) => ({
                            ...question,
                            order: index,
                          })),
                        })),
                      },
                    },
                  }),
                );

                this.requestPageChange.emit(result.pageIndex);
                this.requestEditQuestion.emit(duplicateQuestion);
              },
            });
        },
      });
  }

  removeQuestion(event, question): void {
    this.checkShouldPropagate(this.hasChanges, event);
    const currentQuestionClicked = this.isCurrentQuestionClicked(question);

    if (this.hasChanges && currentQuestionClicked) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.afterDelete.emit(question);
    } else if (this.hasChanges && !currentQuestionClicked) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    } else {
      // Normal from
      event.preventDefault();
      event.stopImmediatePropagation();
      this.afterDelete.emit(question);
    }
  }

  onDragStart(event, question): void {
    event.stopImmediatePropagation();
    const dragData = {
      question,
      reorder: true,
    };
    this.dragService.setDragData(dragData);
    this.store.dispatch(setDragged({ dragged: true }));
  }

  onDragEnd(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.dragService.setDragData(false);
  }

  editControls({ controls, forceUpdateQuestion }): void {
    if (isEqual(controls, this.question.controls)) {
      return;
    }
    this.store.dispatch(updateQuestionField({ field: 'controls', value: controls }));

    const question = {
      ...this.question,
      controls,
    };

    this.store.dispatch(formUpdateQuestion({ question }));

    if (forceUpdateQuestion) {
      // give some time question fields to be updated
      this.store
        .select(selectCurrentQuestion)
        .pipe(take(1))
        .subscribe({
          next: (question: QuestionModel) => {
            this.handleRequestQuestionUpdate(question);
          },
        });
    }
  }

  onQuestionDragStart(event): void {
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.afterDragStart.emit(event);
  }

  onQuestionDragEnd(): void {
    this.afterDragEnd.emit();
  }

  setSelectEditMode(data: {
    control?: ControlModel;
    type: FormElementsEnum;
    parentDataField: string;
    skipUpdateCurrentQuestion?: boolean;
  }): void {
    if (!this.allowSelectEditMode) {
      return;
    }

    if (data?.control && !data.skipUpdateCurrentQuestion) {
      // if control is clicked current question should be marked as active
      // the rule above is only for controls that are not in a group
      this.store.dispatch(populateCurrentQuestion({ question: this.question }));
    }

    this.selectEditMode.emit(data);
  }

  handleRequestQuestionUpdate(question: QuestionModel): void {
    this.saveQuestion(new Event('fakeEvent'), question);
  }

  private isCurrentQuestionClicked(questionFromComponent: QuestionModel) {
    const questionFromState = getState(this.store).question.current.question;
    return questionFromComponent.id === questionFromState.id;
  }

  private checkShouldPropagate(hasChanges: boolean, event: any) {
    if (this.hasChanges) {
      event.stopImmediatePropagation();
    }
  }

  private generateQuestionTitle(question: QuestionModel): string {
    return this.increaseQuestionTitleCopyNumber(question.title || '');
  }

  private increaseQuestionTitleCopyNumber(title: string): string {
    const newTitle = this.utilsService.increaseTitleCopyNumber(title, 30);
    const form = getState(this.store).form.current.form;
    form.questions.forEach((questionId: number) => {
      if (form.questionsPopulated[questionId].title === newTitle) {
        return this.increaseQuestionTitleCopyNumber(newTitle);
      }
    });

    return newTitle;
  }
}
