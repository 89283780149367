<phar-editor-template [controlType]="FormElementsEnum.Boolean">
  <phar-question-editor-label general [disabled]="disabled" />
  <form response [formGroup]="form">
    <mat-label class="inline-block required-field">Response options</mat-label>

    <div class="d-flex justify-items-between align-items-start">
      <div class="d-flex flex-column align-items-start w-100">
        <mat-form-field class="mat-input-small remove-will-change w-100">
          <!--<phar-html-input
            formControlName="labelFalse"
            placeholder="NO"
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength"
          >
          </phar-html-input>

          <mat-hint class="hint-focus-visible">Entered characters {{ form.get('labelFalse').value.length }}
            /{{ labelMaxLength }}
          </mat-hint>-->

          <input
            matInput
            formControlName="labelFalse"
            type="text"
            placeholder="NO"
            pharInputTrim
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength" />

          <mat-error>
            @if (form.get('labelFalse').hasError('required')) {
              Label is <strong>required</strong>
            }
            <!--@if (form.get('labelFalse').hasError('maxlength')) {
              Entered characters {{ form.get('labelFalse').value.length }}/{{ labelMaxLength }}
            }-->
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="d-flex flex-column me-3">
        <mat-label>Value</mat-label>
        <mat-form-field>
          <input
            matInput
            formControlName="valueFalse"
            type="text"
            placeholder="false"
            [maxlength]="50"
            [pharMaxLengthTooltip]="50"
            pharInputTrim>

          <mat-error class="mb-3"  *ngIf="form.get('valueFalse').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>


        </mat-form-field>
      </div> -->
    </div>

    <div class="d-flex justify-items-between align-items-start mb-1">
      <div class="d-flex flex-column align-items-start w-100">
        <mat-form-field class="remove-will-change w-100">
          <!--<phar-html-input
            formControlName="labelTrue"
            placeholder="YES"
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength"
          >
          </phar-html-input>

          <mat-hint class="hint-focus-visible">Entered characters {{ form.get('labelTrue').value.length }}
            /{{ labelMaxLength }}
          </mat-hint>-->
          <input
            matInput
            formControlName="labelTrue"
            type="text"
            placeholder="YES"
            pharInputTrim
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength" />

          <mat-error>
            @if (form.get('labelTrue').hasError('required')) {
              Label is <strong>required</strong>
            }
            <!-- @if (form.get('labelTrue').hasError('maxlength')) {
               Entered characters {{ form.get('labelTrue').value.length }}/{{ labelMaxLength }}
             }-->
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="d-flex flex-column me-3">
        <mat-label>Value</mat-label>
        <mat-form-field>
          <input
            matInput
            formControlName="valueTrue"
            type="text"
            placeholder="true"
            [maxlength]="50"
            [pharMaxLengthTooltip]="50"
            pharInputTrim>

          <mat-error *ngIf="form.get('valueTrue').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>

        </mat-form-field>

      </div> -->
    </div>
    @if (form.hasError('notUnique')) {
      <mat-error>Labels must be <strong>unique.</strong></mat-error>
    }

    <mat-label>Orientation</mat-label>
    <mat-form-field class="w-100">
      <mat-select formControlName="horizontal">
        @for (option of orientationDropdownOptions; track $index) {
          <mat-option [value]="option.value">
            <span>{{ option.label }}</span>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <div></div>
  </form>
</phar-editor-template>
