<div class="d-flex flex-column" [formGroup]="form">
  @if (showQuestionIdEditor) {
    <mat-label class="required-field"
      >Question Title/ID
      <phar-help-icon helpText="A unique identifier to make it easier to search for questions" />
    </mat-label>
    <mat-form-field>
      <textarea
        class="text-area-scrollable"
        formControlName="title"
        cdkAutosizeMaxRows="20"
        cdkAutosizeMinRows="1"
        matInput
        cdkTextareaAutosize
        pharInputTrim
        placeholder="Enter a question title"
        [maxlength]="250"
        [pharMaxLengthTooltip]="250">
      </textarea>
      <mat-error> Title is <strong>required</strong> </mat-error>
    </mat-form-field>
  }
  @if (showQuestionLabelAndInfo) {
    <mat-label class="required-field"
      >Question
      <phar-help-icon
        helpText="The question text to be displayed to the participant, investigator or other clinician" />
    </mat-label>
    <mat-form-field class="remove-will-change">
      <!--<phar-html-input
        [maxlength]="labelMaxLength"
        [pharMaxLengthTooltip]="labelMaxLength"
        formControlName="label"
        placeholder="Enter Question">
      </phar-html-input>

      <mat-hint class="hint-focus-visible">Entered characters {{ form.get('label').value.length }}/{{ labelMaxLength }}
      </mat-hint>-->

      <textarea
        class="text-area-scrollable"
        formControlName="label"
        placeholder="Enter Question"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
        pharInputTrim
        [maxlength]="labelMaxLength"
        [pharMaxLengthTooltip]="labelMaxLength">
      </textarea>

      <mat-error>
        @if (form.get('label').hasError('required')) {
          Label is <strong>required</strong>
        } @else if (form.get('label').hasError('notUniqueLabel')) {
          Question must be <b>unique</b>
        }
        <!--@else if (form.get('label').hasError('maxlength')) {
          Entered characters {{ form.get('label').value.length }}/{{ labelMaxLength }}
        }-->
      </mat-error>
    </mat-form-field>
    <mat-label
      >Optional
      <phar-help-icon
        helpText="Optional information that can be added to a question and displayed in a separate paragraph to the participant, investigator or other clinician" />
    </mat-label>
    <mat-form-field class="remove-will-change">
      <!--<phar-html-input
        formControlName="info"
        placeholder="Enter additional info"
        [maxlength]="infoMaxLength"
        [pharMaxLengthTooltip]="infoMaxLength"
      >
      </phar-html-input>

      <mat-hint class="hint-focus-visible">Entered characters {{ form.get('info').value.length }}/{{ infoMaxLength }}
      </mat-hint>-->
      <textarea
        class="text-area-scrollable"
        formControlName="info"
        placeholder="Enter additional info"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
        pharInputTrim
        [maxlength]="infoMaxLength"
        [pharMaxLengthTooltip]="infoMaxLength">
      </textarea>

      <!--<mat-error>
        Entered characters {{ form.get('info').value.length }}/{{ infoMaxLength }}
      </mat-error>-->
    </mat-form-field>
  }
</div>
